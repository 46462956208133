/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		$.easing.myEasing = function(x, t, b, c, d) {
			return c * ((t = t / d - 1) * t * t + 1) + b;
		};
		$("#searchitem").hover(function() {
			$(".mobilesearchbutton").css("background-position", "0 -30px");
		}, function() {
			$(".mobilesearchbutton").css("background-position", "0 0");
		});
		$(".navbar-toggle").click(function() {
			$("#searchDiv2").css("display", "none");
			if ($(".navbar-collapse").is(":visible")) {
				$(".navbar-collapse").collapse("hide");
			} else {
				$(".navbar-collapse").collapse("show");
			}
		});
		$("#searchitem").click(function() {
			$(".navbar-collapse").collapse("hide");
			$("#searchDiv2").slideToggle(
				300, 
				'myEasing'
			);
		});
		$(".searchbutton").click(function() {
			$("#searchDiv").slideToggle(
				300, 
				'myEasing'
			);
		});

		$(".togglearrownew").click(function() {
			if ($(this).parent().parent().find(".children").is(":visible")) {
				$(this).parent().parent().find(".children").slideUp(
					300, 
					'myEasing'
				);
				$(this).removeClass("arrowupwhite");
				$(this).addClass("arrowdownwhite");
				$(this).parent().parent().find(".maincategorydiv").removeClass("bottomsquare");
				$(this).parent().parent().find(".maincategorydiv").addClass("allround");
				if ($(this).parent().parent().find(".subcategorycontainer").hasClass("showcontainer")) {
					$(this).parent().parent().find(".maincategorydiv").removeClass("bottomsquare");
					$(this).parent().parent().find(".maincategorydiv").addClass("allround");
				}
			} else {
				$(this).parent().parent().find(".children").slideDown(
					300, 
					'myEasing'
				);
				$(this).removeClass("arrowdownwhite");
				$(this).addClass("arrowupwhite");
				$(this).parent().parent().find(".maincategorydiv").removeClass("allround");
				$(this).parent().parent().find(".maincategorydiv").addClass("bottomsquare");
			}
		});
		$(".togglearrow").click(function() {
			if ($(this).parent().parent().find(".subcategorycontainer").is(":visible")) {
				$(this).parent().parent().find(".subcategorycontainer").slideUp(
					300, 
					'myEasing'
				);
				$(this).removeClass("arrowupwhite");
				$(this).addClass("arrowdownwhite");
				$(this).parent().parent().find(".maincategorydiv").removeClass("bottomsquare");
				$(this).parent().parent().find(".maincategorydiv").addClass("allround");
				if ($(this).parent().parent().find(".subcategorycontainer").hasClass("showcontainer")) {
					$(this).parent().parent().find(".maincategorydiv").removeClass("bottomsquare");
					$(this).parent().parent().find(".maincategorydiv").addClass("allround");
				}
			} else {
				$(this).parent().parent().find(".subcategorycontainer").slideDown(
					300, 
					'myEasing'
				);
				$(this).removeClass("arrowdownwhite");
				$(this).addClass("arrowupwhite");
				$(this).parent().parent().find(".maincategorydiv").removeClass("allround");
				$(this).parent().parent().find(".maincategorydiv").addClass("bottomsquare");
			}
		});
		$(".maincategorydiv").hover(function() {
			if ($(this).hasClass("noncurrent")) {
				if ($(this).find(".togglearrow").hasClass("arrowupgrey")) {
					$(this).find(".togglearrow").removeClass("arrowupgrey");
					$(this).find(".togglearrow").addClass("arrowupwhite");
				}
				if ($(this).find(".togglearrow").hasClass("arrowdowngrey")) {
					$(this).find(".togglearrow").removeClass("arrowdowngrey");
					$(this).find(".togglearrow").addClass("arrowdownwhite");
				}
			}
		}, function() {
			if ($(this).hasClass("current")) {
				if ($(this).find(".togglearrow").hasClass("arrowupgrey")) {
					$(this).find(".togglearrow").removeClass("arrowupgrey");
					$(this).find(".togglearrow").addClass("arrowupwhite");
				}
				if ($(this).find(".togglearrow").hasClass("arrowdowngrey")) {
					$(this).find(".togglearrow").removeClass("arrowdowngrey");
					$(this).find(".togglearrow").addClass("arrowdownwhite");
				}
			}
			if ($(this).hasClass("noncurrent")) {
				if ($(this).find(".togglearrow").hasClass("arrowupwhite")) {
					$(this).find(".togglearrow").removeClass("arrowupwhite");
					$(this).find(".togglearrow").addClass("arrowupgrey");
				}
				if ($(this).find(".togglearrow").hasClass("arrowdownwhite")) {
					$(this).find(".togglearrow").removeClass("arrowdownwhite");
					$(this).find(".togglearrow").addClass("arrowdowngrey");
				}
			}
		});
		
		$(".term-53").find(".masteringdiv").removeClass("noncurrent");
		$(".term-53").find(".masteringdiv").addClass("current");
		
		$(".subcategorydiv").each(function(){
			if ($(this).hasClass("subcurrent")) {
				$(this).parent().parent().find(".togglearrow").removeClass("arrowupgrey");
				$(this).parent().parent().find(".togglearrow").addClass("arrowupwhite");
				$(this).parent().parent().find(".maincategorydiv").removeClass("noncurrent");
				$(this).parent().parent().find(".maincategorydiv").addClass("current");
				$(this).parent().addClass("showcontainer");
				$(".titletext").html("<h1>"+$(this).parent().parent().find("a").html()+": "+$(this).text()+"</h1>");
			}
		});
		$(".maincategorydiv").each(function(){
			if ($(this).parent().find(".subcategorycontainer").hasClass("showcontainer")) {
				$(this).css({
					"-webkit-border-top-left-radius": "5px",
					"-webkit-border-top-right-radius": "5px",
					"-moz-border-radius-topleft": "5px",
					"-moz-border-radius-topright": "5px",
					"border-top-left-radius": "5px",
					"border-top-right-radius": "5px",
					"-webkit-border-bottom-right-radius": "0px",
					"-webkit-border-bottom-left-radius": "0px",
					"-moz-border-radius-bottomright": "0px",
					"-moz-border-radius-bottomleft": "0px",
					"border-bottom-right-radius": "0px",
					"border-bottom-left-radius": "0px"
				});
			}
		});
		
		$("#menu-primary-navigation .menu-compliance").addClass("dropdown");
		$("#menu-primary-navigation .menu-compliance").click(function(){
			window.location = siteUrl+"compliance/overview/";
		});
		$(".compliance-navigation-dropdown").appendTo("#menu-primary-navigation .menu-compliance"); 
		$("#menu-primary-navigation .menu-compliance > a").addClass("dropdown-toggle");
		$("#menu-primary-navigation .menu-compliance > a").attr("data-toggle", "dropdown");  
		$("#menu-primary-navigation .menu-compliance > a").attr("data-target", "#");
		
		$("#menu-primary-navigation .menu-practice-management").addClass("dropdown");
		$("#menu-primary-navigation .menu-practice-management").click(function(){
			window.location = siteUrl+"practice-management/overview/";
		});		
		$(".pm-navigation-dropdown").appendTo("#menu-primary-navigation .menu-practice-management"); 
		$("#menu-primary-navigation .menu-practice-management > a").addClass("dropdown-toggle");
		$("#menu-primary-navigation .menu-practice-management > a").attr("data-toggle", "dropdown");  
		$("#menu-primary-navigation .menu-practice-management > a").attr("data-target", "#");
		
		$("#menu-primary-navigation .menu-corporates").addClass("dropdown");
		$("#menu-primary-navigation .menu-corporates").click(function(){
			window.location = siteUrl+"corporates/overview/";
		});
		$(".corporates-navigation-dropdown").appendTo("#menu-primary-navigation .menu-corporates"); 
		$("#menu-primary-navigation .menu-corporates > a").addClass("dropdown-toggle");
		$("#menu-primary-navigation .menu-corporates > a").attr("data-toggle", "dropdown");  
		$("#menu-primary-navigation .menu-corporates > a").attr("data-target", "#");
		
		$("#menu-primary-navigation .menu-get-in-touch").addClass("dropdown");
		$("#menu-primary-navigation .menu-get-in-touch a:first-child").click(function(){
			window.location = siteUrl+"get-in-touch";
		});
		$(".get-in-touch-navigation-dropdown").appendTo("#menu-primary-navigation .menu-get-in-touch"); 
		$("#menu-primary-navigation .menu-get-in-touch > a").addClass("dropdown-toggle");
		$("#menu-primary-navigation .menu-get-in-touch > a").attr("data-toggle", "dropdown");  
		$("#menu-primary-navigation .menu-get-in-touch > a").attr("data-target", "#");
		
		if (Modernizr.mq("only screen and (min-width: 768px)")) {
			$(document).on("click", ".dropdown-toggle", function() {
				if ($(this).parent().hasClass("dropdown")) {
					event.preventDefault();
					$(this).parent().toggleClass("open");
				}
			});
			$("#menu-primary-navigation li").hover(function(){
				if ($(this).hasClass("dropdown")) {
					$(this).addClass("open");
				}
			},function () {
				if ($(this).hasClass("dropdown")) {
					$(this).removeClass("open");
				}
			});
			$(".compliance-navigation-dropdown").hover(function() {
				$(".menu-compliance a.dropdown-toggle").addClass("ddcurrent");
			},function () {
				$(".menu-compliance a.dropdown-toggle").removeClass("ddcurrent");
			});
			$(".pm-navigation-dropdown").hover(function() {
				$(".menu-practice-management a.dropdown-toggle").addClass("ddcurrent");
			},function () {
				$(".menu-practice-management a.dropdown-toggle").removeClass("ddcurrent");
			});
			$(".corporates-navigation-dropdown").hover(function() {
				$(".menu-corporates a.dropdown-toggle").addClass("ddcurrent");
			},function () {
				$(".menu-corporates a.dropdown-toggle").removeClass("ddcurrent");
			});
			$(".get-in-touch-navigation-dropdown").hover(function() {
				$(".menu-get-in-touch a.dropdown-toggle").addClass("ddcurrent");
			},function () {
				$(".menu-get-in-touch a.dropdown-toggle").removeClass("ddcurrent");
			});
		}
		
		if (Modernizr.mq("only screen and (max-width: 767px)")) {
			$(".corporates-navigation-dropdown").removeClass("pull-right");
			$(".get-in-touch-navigation-dropdown").removeClass("pull-right");
			$("#menu-compliance-navigation-dropdown").prepend("<li><a href=\""+siteUrl+"compliance/overview/\">OVERVIEW</a></li>");
			$("#menu-practice-management-navigation-dropdown").prepend("<li><a href=\""+siteUrl+"practice-management/overview/\">OVERVIEW</a></li>");
			$("#menu-corporates-navigation-dropdown").prepend("<li><a href=\""+siteUrl+"corporates/overview/\">OVERVIEW</a></li>");
		}
				
		$(".flex-video").hover(function(){
			$("#play").stop(true,true).animate({
				opacity: 0.5
			}, 300);
		}, function () {
			$("#play").stop(true,true).animate({
				opacity: 1
			}, 500);
		});
		
		$(".single-post .menu-media").addClass("active");
		$(".single-career-position .menu-careers").addClass("active");
		
		if (pageId !== undefined && pageId) {
			if (pageId === 165 || pageId === 169 || pageId === 171 || pageId === 167 || pageId === 320 || pageId === 322) {
				$(".menu-compliance").addClass("active");
			}
			if (pageId === 185 || pageId === 181 || pageId === 183 || pageId === 337 || pageId === 340 || pageId === 179) {
				$(".menu-practice-management").addClass("active");
			}
			if (pageId === 194 || pageId === 196 || pageId === 198 || pageId === 200 || pageId === 350 || pageId === 352) {
				$(".menu-corporates").addClass("active");
			}
		}
		
		$("ol.wp-paginate li span.current").wrap("<a class='nolink' onclick='return false;'></a>");
		$("ol.wp-paginate li a").css({
			"border-top": "1px solid #c4c6c5",
			"border-bottom": "1px solid #c4c6c5"
		});
		$("ol.wp-paginate li a").addClass("innerli");
		$("ol.wp-paginate li a:contains('previous')").css({
			"width": "105px",
			"float": "left",
			"padding": "3px 10px 6px 10px",
			"position": "relative",
			"left": "-2px",
			"top": "-6px",
			"border": "1px solid #c4c6c5",
			"-moz-border-radius": "5px",
			"-webkit-border-radius": "5px",
			"border-radius": "5px"
		});
		$("ol.wp-paginate li a:contains('previous')").removeClass("innerli");
		$("ol.wp-paginate li a:contains('next')").css({
			"width": "105px",
			"float": "right",
			"padding": "3px 10px 6px 10px",
			"position": "relative",
			"left": "2px",
			"top": "-6px",
			"border": "1px solid #c4c6c5",
			"-moz-border-radius": "5px",
			"-webkit-border-radius": "5px",
			"border-radius": "5px"
		});
		$("ol.wp-paginate li a:contains('next')").removeClass("innerli");
		$("ol.wp-paginate li a.innerli:first").css({
			"border-left": "1px solid #c4c6c5",
			"-webkit-border-top-left-radius": "5px",
			"-webkit-border-bottom-left-radius": "5px",
			"-moz-border-radius-topleft": "5px",
			"-moz-border-radius-bottomleft": "5px",
			"border-top-left-radius": "5px",
			"border-bottom-left-radius": "5px"
		});
		$("ol.wp-paginate li a.innerli:last").css({
			"border-right": "1px solid #c4c6c5",
			"-webkit-border-top-right-radius": "5px",
			"-webkit-border-bottom-right-radius": "5px",
			"-moz-border-radius-topright": "5px",
			"-moz-border-radius-bottomright": "5px",
			"border-top-right-radius": "5px",
			"border-bottom-right-radius": "5px"
		});
		if ($("ol.wp-paginate li a.innerli:first").hasClass("nolink")) {
			$("ol.wp-paginate").prepend("<li class='dummyleftli'><a class='nolink' onclick='return false;'></a></li>");
		}
		if ($("ol.wp-paginate li a.innerli:last").hasClass("nolink")) {
			$("ol.wp-paginate").append("<li class='dummyrightli'><a class='nolink' onclick='return false;'></a></li>");
		}
		
		var offset = 220;
		var duration = 500;
		jQuery(window).scroll(function() {
			if (jQuery(this).scrollTop() > offset) {
				jQuery('.back-to-top').fadeIn(duration);
			} else {
				jQuery('.back-to-top').fadeOut(duration);
			}
		});
		jQuery('.back-to-top').click(function(event) {
			event.preventDefault();
			jQuery('html, body').animate({scrollTop: 0}, duration);
			return false;
		});
		$(".footerlink").click(function(){
			return false;
		});
		$("#menu-footer-navigation").addClass("footer");
		$("#menu-footer-navigation a").addClass("footerlink");
		$("#menu-footer-navigation a").attr("data-toggle", "modal");
		var i = 0;
		var j = 0;
		for (i=0;i<3;i++) {
			j = i + 1;
			$("#menu-footer-navigation a:eq("+i+")").attr("data-target", "#modal"+j);
		}
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		$("#carousel-inner-main").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 8000,
			adaptiveHeight: true,
			dots: false,
			centerMode: false
		});
		$("#carousel-inner-testimonials").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			adaptiveHeight: true,
			dots: true,
			centerMode: false
		});
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        $("#carousel-overview-testimonials").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			adaptiveHeight: true,
			dots: true,
			centerMode: false
		});
      }
    },
	// Overview
    'overview': {
      init: function() {
        $("#carousel-overview-testimonials").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			adaptiveHeight: true,
			dots: true,
			centerMode: false
		});
      }
    },
	// Careers
    'careers': {
      init: function() {
        // JavaScript to be fired on the careers page
		$(".careerblock").each(function(){
			$(this).hover(function() {
				$(this).find(".button").addClass("buttonhighlight");
			}, function() {
				$(this).find(".button").removeClass("buttonhighlight");
			});
		});
      }
    },
	// Search
    'search': {
      init: function() {
		$(".careerblock").each(function(){
			$(this).hover(function() {
				$(this).find(".button").addClass("buttonhighlight");
			}, function() {
				$(this).find(".button").removeClass("buttonhighlight");
			});
		});
      }
    },
    // Archive
    'archive': {
      init: function() {
        // JavaScript to be fired on the archive page
		if ($("body").hasClass("post-type-archive-espresso_events")) {
			$(".menu-events").addClass("active");
			$(".menu-media").removeClass("active");
			$("title").html("Events - Masthead");
		} else {
			$(".menu-events").removeClass("active");
			$(".menu-media").addClass("active");
		}
      }
    },
	// Error 404
    'error404': {
      init: function() {
        // JavaScript to be fired on the error page
		$(".menu-media").removeClass("active");
      }
    },
    // Registration Checkout
    'registration_checkout': {
      init: function() {
        // JavaScript to be fired on the events page
		$(".menu-events").addClass("active");
      }
    },
    // Thank You
    'thank_you': {
      init: function() {
        // JavaScript to be fired on the events page
		$(".menu-events").addClass("active");
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function equalHeight(group) {
	if ($(document).width() >= 767) {	
		group.removeAttr("style");
		var tallest = 0;
		group.each(function() {
			var thisHeight = $(this).height();
			if(thisHeight > tallest) {
				tallest = thisHeight;
			}
		});
		group.height(tallest);
	} else {
		group.removeAttr("style");
	}
}

function balanceHeight() {
	$(".contentRow").each(function() {
		equalHeight($(".mainblock", $(this)));
		equalHeight($(".homeblock", $(this)));
		equalHeight($(".postblock", $(this)));
		equalHeight($(".regionalblock", $(this)));
		equalHeight($(".offeringblock", $(this)));
		equalHeight($(".overviewblock", $(this)));
		equalHeight($(".searchblock", $(this)));
	});
	$("#mediastrip").each(function() {
		equalHeight($(".overviewblock", $(this)));
		equalHeight($(".reglibblock", $(this)));
		equalHeight($(".reglibblockinner", $(this)));
	});
}

function setLogo() {
	var paddingtop = parseInt((100 - $(".vertical-align-wrap img").height())/2 - 3);
	$(".vertical-align-wrap img").css("padding-top", paddingtop+"px");
}

$(window).load(function(){
	balanceHeight();
	setLogo();
	$("#preloader").hide();
	$("#carousel-main").show();
	$("#carousel-main-mobile").show();
});	

$(window).resize(function(){
	balanceHeight();
	setLogo();
});